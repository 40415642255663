import { Box, styled } from '@mui/material';
import { HtmlHead } from 'layouts/HtmlHead';
import type { FC, ReactNode } from 'react';
import React from 'react';

interface Props {
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  className?: string;
  removeBottomPadding?: boolean;
  seo?: {
    title?: string;
    description?: string;
  };
  align?: boolean;
}

interface MainProps {
  fullHeight?: boolean;
  fullWidth?: boolean;
  removeBottomPadding?: boolean;
}

const CustomMain = styled('main')(
  ({ fullHeight, fullWidth, removeBottomPadding }: MainProps) => ({
    paddingBlockEnd: removeBottomPadding ? 0 : undefined,
    minWidth: fullWidth ? 'inherit' : undefined,
    height: fullHeight ? '100%' : undefined,
    width: '100%',
    gridArea: 'content',
    position: 'relative',
  }),
);

const Page: FC<Props> = ({
  children,
  removeBottomPadding,
  fullHeight,
  fullWidth,
  className,
  seo,
  align,
}) => (
  <>
    <HtmlHead {...seo} />
    <Box
      position="relative"
      display="grid"
      gridTemplateColumns="minmax(0, 1fr)"
      gridTemplateRows="auto 1fr auto"
      gridTemplateAreas="'header' 'content' 'footer'"
      alignItems="self-start"
      width="100%"
      minHeight="100vh"
      height="auto"
    >
      <CustomMain
        className={className}
        fullHeight={fullHeight}
        fullWidth={fullWidth}
        removeBottomPadding={removeBottomPadding}
      >
        {align ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minWidth="100%"
            height="100%"
          >
            {children}
          </Box>
        ) : (
          children
        )}
      </CustomMain>
    </Box>
  </>
);

export default Page;
