import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import doFetch from 'utils/api/doFetch';

import pageUrls from 'constants/pageUrls';
import { validEmailRegex } from 'constants/regex';

import Button from 'components/Button';

import { useAppDispatch } from 'redux-store';
import { setLoginTransition } from 'redux-store/slices/ui';

const LoginPage: FC = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();

  // const [email, setEmail] = useState<string>('');
  const emailRef = useRef<HTMLInputElement>(null);

  const [emailTouched, setEmailTouched] = useState<boolean>(false);

  // const [password, setPassword] = useState<string>('');
  const passwordRef = useRef<HTMLInputElement>(null);

  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [genericError, setGenericError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | true | null>(null);
  const [passwordError, setPasswordError] = useState<string | true | null>(
    null,
  );

  const doLogin = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      let error = false;

      const email = emailRef.current?.value || '';
      const password = passwordRef.current?.value || '';

      if (email.length < 1 || !validEmailRegex.test(email)) {
        setEmailError(t('common:login_errors.enter_vaild_email'));
        setEmailTouched(false);
        error = true;
      }

      if (password.length < 1) {
        setPasswordError(t('common:login_errors.enter_password'));
        error = true;
      }

      if (error) return;

      setLoading(true);
      try {
        const apiPointName = 'login';

        const result = await doFetch<typeof apiPointName>({
          apiPointName,
          body: {
            email,
            password,
          },
        });

        if (!result.success) {
          throw new Error(result.error);
        }

        dispatch(setLoginTransition(true));

        await router.push(pageUrls.homeDashboard, undefined, {
          locale: router.locale,
        });
      } catch (e) {
        const err = e as { message?: string; name?: string; code?: string };

        // handle error
        if (err.message) {
          if (i18n.exists(err.message)) {
            setGenericError(t(err.message as string) as string);
          } else {
            // TODO: check this
            // console.log(
            //   `Error message "${err.message}" not found in translations`,
            // );
            setGenericError(err.message);
          }
        } else {
          setGenericError('Something went wrong');
        }
        setLoading(false);
      }
    },
    [t, router, i18n, dispatch],
  );

  const handleAnonymousLogin = useCallback(async () => {
    setLoading(true);
    try {
      const apiPointName = 'loginAnonymous';

      const result = await doFetch<typeof apiPointName>({
        apiPointName,
      });

      if (!result.success) {
        throw new Error(result.error);
      }

      dispatch(setLoginTransition(true));

      await router.push(pageUrls.homeDashboard, undefined, {
        locale: router.locale,
      });
    } catch (e) {
      const err = e as { message?: string; name?: string; code?: string };

      // handle error
      if (err.message) {
        if (i18n.exists(err.message)) {
          setGenericError(t(err.message as string) as string);
        } else {
          // TODO: check this
          // console.log(
          //   `Error message "${err.message}" not found in translations`,
          // );
          setGenericError(err.message);
        }
      } else {
        setGenericError('Something went wrong');
      }
      setLoading(false);
    }
  }, [dispatch, i18n, router, t]);

  const handleErrorReset = useCallback(() => {
    if (genericError && (emailError || passwordError)) {
      setEmailError(null);
      setPasswordError(null);
      setGenericError(null);
    }
  }, [genericError, emailError, passwordError]);

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmailTouched(true);
      handleErrorReset();
      const email = event.target.value;

      if ((email.length > 0 || emailTouched) && !validEmailRegex.test(email)) {
        setEmailError(t('common:login_errors.enter_vaild_email'));
      } else {
        setEmailError(null);
      }
    },
    [emailTouched, handleErrorReset, t],
  );

  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleErrorReset();
      const password = event.target.value;

      if (password.length > 0) {
        setPasswordError(null);
      }
    },
    [handleErrorReset],
  );

  return (
    <>
      <Typography variant="h1" align="center">
        {t('common:login')}
      </Typography>
      <Typography variant="h3" align="center" mb={2}>
        {t('common:login_bottom_text')}
      </Typography>
      <Box mb={2}>
        <form onSubmit={doLogin}>
          <Box mb={2}>
            <Typography
              variant="body2"
              color="error"
              style={{ opacity: typeof genericError === 'string' ? 1 : 0 }}
              align="left"
            >
              {typeof genericError === 'string' ? genericError : '_'}
            </Typography>
          </Box>
          <Box mb={3} width="100%">
            <TextField
              inputRef={emailRef}
              id="login-email"
              label={t('common:email') as string}
              variant="filled"
              onChange={handleEmailChange}
              autoComplete="email"
              required
              error={!!emailError}
              helperText={
                typeof emailError === 'string' ? emailError : undefined
              }
              fullWidth
            />
          </Box>
          <Box mb={3} width="100%">
            <TextField
              inputRef={passwordRef}
              id="login-password"
              label={t('common:password') as string}
              variant="filled"
              onChange={handlePasswordChange}
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              required
              error={!!passwordError}
              helperText={
                typeof passwordError === 'string' ? passwordError : undefined
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(): void => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Box width="100%">
            <Button
              variant="flat"
              fullWidth
              color="primary"
              type="submit"
              size="large"
              disabled={isLoading || !!emailError || !!passwordError}
              onClick={doLogin}
              data-testid="submit"
            >
              {t('common:login')}
            </Button>
          </Box>
        </form>
      </Box>
      {process.env.HIDE_UNFINISHED_FEATURES === 'true' ? (
        <Box mb={2}>
          <Typography variant="body1" align="center">
            {t('common:no_account.pre_text')}{' '}
            <Link
              href={pageUrls.register}
              className="ignore-global-css"
              data-testid="signup-link"
            >
              {t('common:no_account.link_text')}
            </Link>
          </Typography>
        </Box>
      ) : null}
      {process.env.HIDE_UNFINISHED_FEATURES === 'true' ? (
        <Divider>{t('common:or')}</Divider>
      ) : null}
      <Box mt={2}>
        <Button
          variant="text"
          fullWidth
          color="primary"
          size="large"
          onClick={handleAnonymousLogin}
          data-testid="login-anonymously"
        >
          {t('common:login_anonymously')}
        </Button>
      </Box>
    </>
  );
};

export default LoginPage;
