interface SliderProps {
  image: string;
  title: string;
  width: number;
  height: number;
}

export const sliderData: SliderProps[] = [
  {
    image: '/assets/pages/user/login/go-e-chargerhome.png',
    title: 'go-e Charger HOMEfix/HOME+',
    width: 400,
    height: 400,
  },
  {
    image: '/assets/pages/user/login/go-e-gemini.png',
    title: 'go-e Charger Gemini',
    width: 400,
    height: 400,
  },
  {
    image: '/assets/pages/user/login/go-e-gemini-flex.png',
    title: 'go-e Charger Gemini Flex',
    width: 400,
    height: 400,
  },
  {
    image: '/assets/devices/go-e_Controller.png',
    title: 'go-e Controller',
    width: 400,
    height: 400,
  },
];
