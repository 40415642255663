import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Page from 'layouts/Page';
import Image from 'next/image';
import React from 'react';
import type { FC } from 'react';
import Slider from 'react-slick';

import LanguageSelectorButton from 'components/LanguageSelectorButton';
import ThemeSwitcher from 'components/ThemeSwitcher';

import LoginPageElements from './LoginPage';
import RegistrationPageElements from './RegistrationPage';
import { sliderData } from './constants';

export interface LoginPageProps {
  isRegistration?: boolean;
}

const UserLandingPage: FC<LoginPageProps> = ({ isRegistration }) => {
  const theme = useTheme();
  const showImageSlider = useMediaQuery('(min-width: 600px)');

  return (
    <Page fullHeight fullWidth align>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        position="absolute"
        top={0}
        right={0}
        zIndex={1}
      >
        <Box paddingRight={4} paddingTop={2}>
          <LanguageSelectorButton small placement="bottom-end" />
          {process.env.SHOW_THEME_SELECTOR === 'true' ? (
            <ThemeSwitcher />
          ) : null}
        </Box>
      </Box>
      <Box
        width={showImageSlider ? '55%' : '100%'}
        height="100%"
        display="flex"
        padding={2}
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        gap={2}
      >
        {showImageSlider ? (
          <Box flex={2}>
            <Box
              bgcolor={theme.vars.palette.background.paper}
              borderRadius={2}
              width={400}
              pb={4}
            >
              <Slider
                dots
                infinite
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay
                autoplaySpeed={5000}
                arrows={false}
                useCSS
              >
                {sliderData.map((item, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.image}-${index}`}
                  >
                    <Image
                      src={item.image}
                      alt={item.title}
                      width={item.width}
                      height={item.height}
                    />
                    <Typography variant="h6" align="center">
                      {item.title}
                    </Typography>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Box>
        ) : null}
        <Box flex={3} padding={2} minWidth="fit-content">
          {isRegistration ? (
            <RegistrationPageElements />
          ) : (
            <LoginPageElements />
          )}
        </Box>
      </Box>
    </Page>
  );
};

export default UserLandingPage;
