import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import doFetch from 'utils/api/doFetch';

import pageUrls from 'constants/pageUrls';
import { validEmailRegex } from 'constants/regex';

import Button from 'components/Button';

import { useAppDispatch } from 'redux-store';
import { setLoginTransition } from 'redux-store/slices/ui';

const RegistrationPage: FC = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>('');
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [isLoading, setLoading] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [genericError, setGenericError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | true | null>(null);
  const [passwordError, setPasswordError] = useState<string | true | null>(
    null,
  );

  // hacky way to prevent autofill from breaking input fields
  useEffect(() => {
    setShowSignup(true);
  }, []);

  const doSignup = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      let error = false;

      if (email.length < 1 || !validEmailRegex.test(email)) {
        setEmailError(t('common:login_errors.enter_vaild_email'));
        setEmailTouched(false);
        error = true;
      }

      if (password.length < 1) {
        setPasswordError(t('common:login_errors.enter_password'));
        error = true;
      }

      if (error) return;

      setLoading(true);
      try {
        const apiPointName = 'register';

        const res = await doFetch<typeof apiPointName>({
          apiPointName,
          body: {
            email,
            password,
          },
        });

        if (!res.success) {
          setGenericError(res.error as string);
          setLoading(false);

          return;
        }

        dispatch(setLoginTransition(true));

        await router.push(pageUrls.login, undefined, {
          locale: router.locale,
        });
      } catch (e) {
        const err = e as { message?: string; name?: string; code?: string };

        // handle error
        if (err.message) {
          // try to translate it first
          if (err.name === 'FirebaseError' && err.code) {
            const translation_key = `firebase_errors.${err.code}`;

            switch (err.code) {
              case 'auth/invalid-email':
                setEmailError(true);
                break;
              case 'auth/wrong-password':
                setPasswordError(true);
                break;
              case 'auth/user-not-found':
                setEmailError(true);
                break;
              default:
                setEmailError(null);
                setPasswordError(null);
                break;
            }

            if (i18n.exists(translation_key)) {
              setGenericError(t(translation_key) as string);
              setLoading(false);

              return;
            } else {
              // TODO: check this
              // console.log(
              //   `Error code "${err.code}" not found in translations`,
              //   translation_key,
              // );
            }
          }

          if (i18n.exists(err.message)) {
            setGenericError(t(err.message as string) as string);
          } else {
            // TODO: check this
            // console.log(
            //   `Error message "${err.message}" not found in translations`,
            // );
            setGenericError(err.message);
          }
        } else {
          setGenericError('Something went wrong');
        }
        setLoading(false);
      }
    },
    [email, password, t, router, i18n, dispatch],
  );

  useEffect(() => {
    // email invalid if not matches
    if ((email.length > 0 || emailTouched) && !validEmailRegex.test(email)) {
      setEmailError(t('common:login_errors.enter_vaild_email'));
    } else {
      setEmailError(null);
    }
  }, [email, emailTouched, t]);

  useEffect(() => {
    if (password.length > 0) {
      setPasswordError(null);
    }
  }, [password]);

  const handleErrorReset = useCallback(() => {
    if (genericError && (emailError || passwordError)) {
      setEmailError(null);
      setPasswordError(null);
      setGenericError(null);
    }
  }, [genericError, emailError, passwordError]);

  if (!showSignup) return null;

  return (
    <>
      <Typography variant="h1" align="center">
        {t('common:signup')}
      </Typography>
      <Typography variant="h3" align="center" mb={2}>
        {t('common:login_bottom_text')}
      </Typography>
      <Box mb={2}>
        <form onSubmit={doSignup}>
          <Box mb={2}>
            <Typography
              variant="body2"
              color="error"
              style={{ opacity: typeof genericError === 'string' ? 1 : 0 }}
              align="left"
            >
              {typeof genericError === 'string' ? genericError : '_'}
            </Typography>
          </Box>
          <Box mb={3} width="100%">
            <TextField
              id="login-email"
              label={t('common:email') as string}
              variant="filled"
              value={email}
              onChange={(e): void => {
                setEmailTouched(true);
                setEmail(e.target.value);
                handleErrorReset();
              }}
              autoComplete="email"
              required
              error={!!emailError}
              helperText={
                typeof emailError === 'string' ? emailError : undefined
              }
              autoFocus
              fullWidth
            />
          </Box>
          <Box mb={3} width="100%">
            <TextField
              id="login-password"
              label={t('common:password') as string}
              variant="filled"
              value={password}
              onChange={(e): void => {
                setPassword(e.target.value);
                handleErrorReset();
              }}
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              required
              error={!!passwordError}
              helperText={
                typeof passwordError === 'string' ? passwordError : undefined
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(): void => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Box width="100%">
            <Button
              fullWidth
              variant="flat"
              color="primary"
              type="submit"
              size="large"
              disabled={isLoading || !!emailError || !!passwordError}
              onClick={doSignup}
              data-testid="submit"
            >
              {t('common:signup')}
            </Button>
          </Box>
        </form>
      </Box>
      <Box>
        <Typography variant="body1" align="center">
          {t('common:have_an_account.pre_text')}{' '}
          <Link
            href={pageUrls.login}
            className="ignore-global-css"
            data-testid="login-link"
          >
            {t('common:have_an_account.link_text')}
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default RegistrationPage;
